export const environment = {
  production: false,
  b2cConfigs: {
    tenantName: 'lawafshpjobportal',
    clientId: '7dd0cdc5-416d-4795-becf-672a7fdf3973',
    redirectUri: 'https://devjobs.flylax.com/',
    logoutRedirectUri: '/',
    policyNames: {
      signUpSignIn: 'B2C_1_lawa_signup_signin',
      editProfile: 'B2C_1_lawa_profile_edit',
      signupJobseeker: 'B2C_1_jobseeker_signup',
      signupEmployer: 'B2C_1_employer_signup',
      resetPassword: 'B2C_1_lawa_pwd_reset',
    },
  },
  umbracoBaseUri: 'https://app-umbraco-fshp-dev-westus3-003.azurewebsites.net',
  apiUrl: 'https://app-api-fshp-dev-westus3-002.azurewebsites.net/api',
};
